import { gql } from '@apollo/client';

const getMasterData = gql`
  query {
    getMasterDataFilters {
      data {
        programs {
          programId
          programName
          programFullName
          products {
            productId
            productName
            models {
              modelId
              modelName
              sectors {
                sectorId
                sectorName
                markets {
                  marketId
                  marketName
                  applicationNames
                  customers {
                    customerName
                    customerId
                  }
                }
                plants {
                  plantId
                  plantName
                }
              }
            }
          }
        }
        categories
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getGSModelFilters = gql`
  query ($modelId: Int) {
    getGSModelFilters(modelId: $modelId) {
      data {
        marketId
        marketName
        retailers {
          retailerName
          regions {
            regionName
            zones {
              zoneName
              districts
            }
          }
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;
const getMetricThreshold = gql`
  query ($thresholdMetrics: ThresholdMetricsRequestDTOInput!) {
    getMetricThreshold(thresholdMetrics: $thresholdMetrics) {
      data {
        productName
        productId
        thresholdMetrics {
          metricName
          minThreshold
          maxThreshold
          unit
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;
const getThemePreference = gql`
  query {
    getDashboardMode {
      data {
        displayName
        email
        gpid
        mode
        lastUpdateDate
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const saveThemePreference = gql`
  query ($mode: String!) {
    setDashboardMode(mode: $mode) {
      data {
        displayName
        email
        gpid
        mode
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getOperationalMetricsOverview = gql`
  query ($operationalMetricCriteria: OperationalMetricesRequestDTOInput!) {
    getOperationalMetricsOverview(
      operationalMetricCriteria: $operationalMetricCriteria
    ) {
      data {
        activeStore
        totalStore
        activeStorePercentage
        shareOfDgtOrderPercentage
        avgLoginPerActiveStore
        incrementalNetRevenue
        activeStoreIncrementalNetRevenue
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getCustomerCallMetrices = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getCustomerCalls(psdCriteria: $psdCriteria) {
      data {
        callVolume
        totalNoOfVisitsKAM
        totalNoOfVisitsNAM
        totalNoOfExportsPerPeriod
        customerVisitTrends {
          dateTime
          totalNoOfVisitsKAM
          totalNoOfVisitsNAM
        }
      }
      errors
      responseCode
      responseMessage
    }
    getMostSearchedKeywords(psdCriteria: $psdCriteria) {
      data {
        searchedKeywordName
        searchedCountPercentage
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getOrderAnalysis = gql`
  query ($orderMetricCriteria: OrderMetricsRequestDTOInput!) {
    getOrderAnalysis(orderMetricCriteria: $orderMetricCriteria) {
      data {
        totalOrder
        orderDetail {
          status
          noOfOrder
          revenue
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getOrderAnalysisGraph = gql`
  query ($orderMetricCriteria: OrderMetricsRequestDTOInput!) {
    getOrderAnalysisGraph(orderMetricCriteria: $orderMetricCriteria) {
      data {
        dateTime
        noOfOrders
        revenue
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getSalesAndDA = gql`
  query ($operationalMetricCriteria: OperationalMetricesRequestDTOInput!) {
    getSalesAndDA(operationalMetricCriteria: $operationalMetricCriteria) {
      data {
        totalNetRevenue
        avgTimeInPlatform
      }
      errors
      responseCode
      responseMessage
    }
  }
`;
const getSalesAndDALoyaltyTable = gql`
  query ($operationalMetricCriteria: OperationalMetricesRequestDTOInput!) {
    getSalesAndDALoyaltyTable(
      operationalMetricCriteria: $operationalMetricCriteria
    ) {
      data {
        dprAndAmrAccrued
        dprAndAmrRealized
        dprAndAmrIncrementalNRAccrued
        dprAndAmrIncrementalNRRealized
      }
      errors
      responseCode
      responseMessage
    }
  }
`;
const getDigitalOrdersRegistration = gql`
  query ($operationalMetricCriteria: OperationalMetricesRequestDTOInput!) {
    getDigitalOrdersRegistration(
      operationalMetricCriteria: $operationalMetricCriteria
    ) {
      data {
        orderingOnceSinceRegistration
        frequentOrderingStore
        orderingOnceSinceRegistrationInPercentage
        frequentOrderingStoreInPercentage
      }
      errors
      responseCode
      responseMessage
    }
  }
`;
const getDigitalOrdersSummary = gql`
  query ($operationalMetricCriteria: OperationalMetricesRequestDTOInput!) {
    getDigitalOrdersSummary(
      operationalMetricCriteria: $operationalMetricCriteria
    ) {
      data {
        totalDigitalOrder
        totalNonDigitalOrder
        activeOrderingStore
      }
      errors
      responseCode
      responseMessage
    }
  }
`;
const getDigitalTotalOrdersCharts = gql`
  query ($operationalMetricCriteria: OperationalMetricesRequestDTOInput!) {
    getDigitalTotalOrdersCharts(
      operationalMetricCriteria: $operationalMetricCriteria
    ) {
      data {
        digitalNetSale
        dateTime
        totalDigitalOrder
      }
      errors
      responseCode
      responseMessage
    }
  }
`;
const getStoreVersusPlan = gql`
  query ($operationalMetricCriteria: OperationalMetricesRequestDTOInput!) {
    getStoreVersusPlan(operationalMetricCriteria: $operationalMetricCriteria) {
      data {
        totalRegisteredStore
        activeStore
        registeredStoreCurrentMonth
        engagedStore
        planRegisteredStore
        planActiveStores
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getStoreAndUsage = gql`
  query ($operationalMetricCriteria: OperationalMetricesRequestDTOInput!) {
    getStoreAndUsage(operationalMetricCriteria: $operationalMetricCriteria) {
      data {
        engagedStore
        totalStore
        rewardsRedeemingStore
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getStoreAndUsageChart = gql`
  query ($operationalMetricCriteria: OperationalMetricesRequestDTOInput!) {
    getStoreAndUsageChart(
      operationalMetricCriteria: $operationalMetricCriteria
    ) {
      data {
        totalPointsEarned
        totalPointsRedeemed
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getAverageNetRevenueStore = gql`
  query ($operationalMetricCriteria: OperationalMetricesRequestDTOInput!) {
    getAverageNetRevenueStore(
      operationalMetricCriteria: $operationalMetricCriteria
    ) {
      data {
        totalNetRevenue
        digitalNetRevenue
        netRevenueInPercentage
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getAverageOrderStore = gql`
  query ($operationalMetricCriteria: OperationalMetricesRequestDTOInput!) {
    getAverageOrderStore(
      operationalMetricCriteria: $operationalMetricCriteria
    ) {
      data {
        totalOrderStore
        digitalOrderStore
        orderStoreInPercentage
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getAverageOrderStoreSize = gql`
  query ($operationalMetricCriteria: OperationalMetricesRequestDTOInput!) {
    getAverageOrderStoreSize(
      operationalMetricCriteria: $operationalMetricCriteria
    ) {
      data {
        digitalOrderStore
        orderStoreInPercentage
        totalOrderStore
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getStoreReachingTarget = gql`
  query ($operationalMetricCriteria: OperationalMetricesRequestDTOInput!) {
    getStoreReachingTarget(
      operationalMetricCriteria: $operationalMetricCriteria
    ) {
      data {
        selectedPeriod {
          dateTime
          summaryMetricValue
        }
        monthlyAvgYearToDate {
          dateTime
          summaryMetricValue
        }
        yearToDate {
          dateTime
          summaryMetricValue
        }
      }
      responseCode
      responseMessage
    }
  }
`;

const getDeviceOsBreakdown = gql`
  query ($applicationMetricCriteria: ApplicationMetricsRequestDTOInput!) {
    getDeviceOsBreakdown(
      applicationMetricCriteria: $applicationMetricCriteria
    ) {
      data {
        osBreakDown {
          osName
          overAllBreakdownPercentage
          versions {
            osVersion
            deviceBreakdownPercentage
          }
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getErrorCrashRateTrend = gql`
  query ($applicationMetricCriteria: ApplicationMetricsRequestDTOInput!) {
    getErrorCrashRateTrend(
      applicationMetricCriteria: $applicationMetricCriteria
    ) {
      data {
        dateTime
        osDetail {
          osName
          errorCrashRateTrend
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getIncidentTrend = gql`
  query ($applicationMetricCriteria: ApplicationMetricsRequestDTOInput!) {
    getIncidentTrend(applicationMetricCriteria: $applicationMetricCriteria) {
      data {
        dateTime
        incidentCount
        resolvedWithInSla
        responseWithInSla
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getUserAdoptionScorecard = gql`
  query ($overviewMetricCriteria: OverviewMetricesRequestDTOInput!) {
    getUserAdoptionScorecard(overviewMetricCriteria: $overviewMetricCriteria) {
      data {
        avgLoginPerActiveStore
        avgTimeInPlatform
        uniquePageViews
        activeUser
        newUser
        totalUser
        userFrequency
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getTopEntryPage = gql`
  query ($overviewMetricCriteria: OverviewMetricesRequestDTOInput!) {
    getTopEntryPage(overviewMetricCriteria: $overviewMetricCriteria) {
      data {
        pageName
        visitedPercentage
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getRageClick = gql`
  query ($overviewMetricCriteria: OverviewMetricesRequestDTOInput!) {
    getRageClick(overviewMetricCriteria: $overviewMetricCriteria) {
      data {
        rageName
        ragePercentage
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getNetworkPerformance = gql`
  query ($applicationMetricCriteria: ApplicationMetricsRequestDTOInput!) {
    getNetworkPerformance(
      applicationMetricCriteria: $applicationMetricCriteria
    ) {
      data {
        countryName
        loadInMs
        crashes
        networkRequestTimeInMs
        networkErrors
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getIncidentMonitoringSlaAnalysis = gql`
  query ($applicationMetricCriteria: ApplicationMetricsRequestDTOInput!) {
    getIncidentMonitoringSlaAnalysis(
      applicationMetricCriteria: $applicationMetricCriteria
    ) {
      data {
        slaType
        incidentCount
        incidentPercentage
        incidentOverallAvgPercentage
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getIncidentMonitoringOpenAndClose = gql`
  query ($applicationMetricCriteria: ApplicationMetricsRequestDTOInput!) {
    getIncidentMonitoringOpenAndClose(
      applicationMetricCriteria: $applicationMetricCriteria
    ) {
      data {
        openIncident
        closedIncident
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getIncidentMonitoringPriorityLevelGraph = gql`
  query ($applicationMetricCriteria: ApplicationMetricsRequestDTOInput!) {
    getIncidentMonitoringPriorityLevelGraph(
      applicationMetricCriteria: $applicationMetricCriteria
    ) {
      data {
        dateTime
        sumOfIncidentCount
        priorityDetail {
          priority
          incidentCount
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;
const getIncidentMonitoringTableView = gql`
  query ($applicationMetricCriteria: ApplicationMetricsRequestDTOInput!) {
    getIncidentMonitoringTableView(
      applicationMetricCriteria: $applicationMetricCriteria
    ) {
      data {
        incidentNumber
        category
        priority
        openDateTime
        assigned
        status
        slaAnalysisType
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getHealthAndStabilityOverview = gql`
  query ($applicationMetricCriteria: ApplicationMetricsRequestDTOInput!) {
    getHealthAndStability(
      applicationMetricCriteria: $applicationMetricCriteria
    ) {
      data {
        availability
        appCrashes
        etoeLatency
        networkErrors
        averagePageLoadTime
        failedApiCalls
      }
      errors
      responseCode
      responseMessage
    }
  }
`;
const getHealthAndStabilityBarChart = gql`
  query ($applicationMetricCriteria: ApplicationMetricsRequestDTOInput!) {
    getHealthAndStabilityBarChart(
      applicationMetricCriteria: $applicationMetricCriteria
    ) {
      data {
        eventTimestamp
        networkRequestTimeInMs
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getIncidentMonitoringOverview = gql`
  query ($applicationMetricCriteria: ApplicationMetricsRequestDTOInput!) {
    getIncidentMonitoringOverview(
      applicationMetricCriteria: $applicationMetricCriteria
    ) {
      data {
        incidentCount
        responseTime
        vsPlanResponseTime
        resolutionTime
        vsPlanResolutionTime
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getHealthAndStabilityLineChart = gql`
  query ($applicationMetricCriteria: ApplicationMetricsRequestDTOInput!) {
    getHealthAndStabilityLineChart(
      applicationMetricCriteria: $applicationMetricCriteria
    ) {
      data {
        eventTimestamp
        networkRequestTimeInMs
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getDigitizedStores = gql`
  query ($operationalMetricCriteria: OperationalMetricesRequestDTOInput!) {
    getDigitizedStores(operationalMetricCriteria: $operationalMetricCriteria) {
      data {
        digitizedStoresInPercentage
        targetRegistrations
        monthlyActiveStores
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getStoreSalesRevenueImpactDtl = gql`
  query ($operationalMetricCriteria: OperationalMetricesRequestDTOInput!) {
    getStoreSalesRevenueImpactDtl(
      operationalMetricCriteria: $operationalMetricCriteria
    ) {
      data {
        current {
          activeStores
          controlStores
        }
        ytd {
          activeStores
          controlStores
        }
        totalIncrementalCmYtd
        totalIncrementalCmImpactMonth
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getGSScoreCard = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getGSScoreCard(psdCriteria: $psdCriteria) {
      data {
        revenue
        basketSize
        skusPerOrder
        skusAdded
        skusRemoved
        soDrivenOrders
        storeUniverse
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getGSOperationalMetrics = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getGSOperationalMetrics(psdCriteria: $psdCriteria) {
      data {
        suggestedOrderPercentage
        actualVolumeIndex
        targetVolumeIndex
        suggestedOverallPercentage
        suggestedOrderBaskets
        orderValueGrowthPercentage
        modalRecallPercentage
        modalPrecisionPercentage
        f1ScorePercentage
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getPepsiConnectScoreCard = gql`
  query ($operationalMetricCriteria: OperationalMetricesRequestDTOInput!) {
    getTotalOrderProcessed(
      operationalMetricCriteria: $operationalMetricCriteria
    ) {
      data {
        totalOrderProcessedForLast24Hours
      }
      errors
      responseCode
      responseMessage
    }
    getTotalRevenueGenerated(
      operationalMetricCriteria: $operationalMetricCriteria
    ) {
      data {
        totalRevenueGeneratedForLast24Hours
      }
      errors
      responseCode
      responseMessage
    }
  }
`;
const getBeesScoreCard = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getTotalOrderInBees(psdCriteria: $psdCriteria) {
      data {
        totalOrderInBees
      }
      errors
      responseCode
      responseMessage
    }
    getGrossRevenueInBees(psdCriteria: $psdCriteria) {
      data {
        grossRevenueInBees
      }
      errors
      responseCode
      responseMessage
    }
    getTotalOrderInPepsiConnect(psdCriteria: $psdCriteria) {
      data {
        totalOrderInPepsiConnect
      }
      errors
      responseCode
      responseMessage
    }
    getGrossRevenueInPepsiConnect(psdCriteria: $psdCriteria) {
      data {
        grossRevenueInPepsiConnect
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getBeesVsPepsiConnectSRT = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getBeesVsPepsiConnectSRT(psdCriteria: $psdCriteria) {
      data {
        date
        targetInBees
        percentageOfTargetInBees
        targetInPepsiConnect
        percentageOfTargetInPepsiConnect
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getBeesVsPepsiConnectGrossRevenue = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getBeesVsPepsiConnectGrossRevenue(psdCriteria: $psdCriteria) {
      data {
        date
        grossRevenueInBees
        grossRevenueInPepsiConnect
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getBeesVsPepsiConnectOrder = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getBeesVsPepsiConnectOrder(psdCriteria: $psdCriteria) {
      data {
        date
        ordersInBees
        ordersInPepsiConnect
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getBeesVsPepsiConnectDropSizeOrder = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getBeesVsPepsiConnectDropSizeOrder(psdCriteria: $psdCriteria) {
      data {
        date
        dropSizeOrdersInBees
        dropSizeOrdersInPepsiConnect
      }
      errors
      responseCode
      responseMessage
    }
  }
`;
const getSuggestedOrdersRealTime = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getSuggestedOrdersRealTime(psdCriteria: $psdCriteria) {
      data {
        totalSuggestedOrders
        storesPlacingSuggestedOrders
      }
      errors
      responseCode
      responseMessage
    }
  }
`;
const getSuggestedOrdersRealTimeTrend = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getSuggestedOrdersRealTimeTrend(psdCriteria: $psdCriteria) {
      data {
        dateTime
        totalSuggestedOrders
        storesPlacingSuggestedOrders
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getSalesUplift = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getSalesUplift(psdCriteria: $psdCriteria) {
      data {
        salesUpliftValue
        salesUpliftValueOverLastMonth
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getNetPromoterScore = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getNetPromoterScore(psdCriteria: $psdCriteria) {
      data {
        date
        sector
        market
        npsScore
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getRevenueIndicator = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getRevenueIndicator(psdCriteria: $psdCriteria) {
      data {
        avgSuggestedVsNonSuggestedOrders
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getAvgSoSizeIndex = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getAvgSoSizeIndex(psdCriteria: $psdCriteria) {
      data {
        date
        avgSoSizeIndex
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

// Product Paring Section
const getAllRecommendationServedTrend = gql`
  query ($gsPPMetricCriteria: GSPPMetricsRequestDTOInput!) {
    getAllRecommendationServedTrend(gsPPMetricCriteria: $gsPPMetricCriteria) {
      data {
        dateTime
        totalRecommendationServed
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getGSPPScoreCard = gql`
  query ($gsPPMetricCriteria: GSPPMetricsRequestDTOInput!) {
    getGSPPScoreCard(gsPPMetricCriteria: $gsPPMetricCriteria) {
      data {
        storesWithPPRecommendation
        totalRecommendation
        totalPPRecommendationInSellSheet
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getGSSWScoreCard = gql`
  query ($gsPPMetricCriteria: GSPPMetricsRequestDTOInput!) {
    getShareWiseOPScoreCard(gsPPMetricCriteria: $gsPPMetricCriteria) {
      data {
        storesWithSWRecommendations
        totalRecommendations
        recommendationAddedInSellSheet
        saltySubCatogeriesCount
        savorySubCatogeriesCount
        macroSubCatogeriesCount
      }
      errors
      responseCode
      responseMessage
    }
  }
`;
const getRecommendationAddedToSellSheetTrend = gql`
  query ($gsPPMetricCriteria: GSPPMetricsRequestDTOInput!) {
    getRecommendationAddedToSellSheetTrend(
      gsPPMetricCriteria: $gsPPMetricCriteria
    ) {
      data {
        dateTime
        totalRecommendationAdded
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getGSPPAcceptanceRate = gql`
  query ($gsPPMetricCriteria: GSPPMetricsRequestDTOInput!) {
    getGSPPAcceptanceRate(gsPPMetricCriteria: $gsPPMetricCriteria) {
      data {
        addedPPToSellSheet
        allPPServed
        acceptanceRate
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getTotalNoOfRecommendationForRegion = gql`
  query ($gsPPMetricCriteria: GSPPMetricsRequestDTOInput!) {
    getTotalNoOfRecommendationForRegion(
      gsPPMetricCriteria: $gsPPMetricCriteria
    ) {
      data {
        regionName
        totalRecommendation
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getRecommendationAddedToSellSheetForRegion = gql`
  query ($gsPPMetricCriteria: GSPPMetricsRequestDTOInput!) {
    getRecommendationAddedToSellSheetForRegion(
      gsPPMetricCriteria: $gsPPMetricCriteria
    ) {
      data {
        regionName
        totalRecommendationAdded
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

// App Performance Redesign Queries
const getUniqueUserTrend = gql`
  query ($applicationMetricCriteria: ApplicationMetricsRequestDTOInput!) {
    getUniqueUserTrend(applicationMetricCriteria: $applicationMetricCriteria) {
      data {
        dateTime
        osDetails {
          osName
          totalUser
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getAppCrashReportTrend = gql`
  query ($applicationMetricCriteria: ApplicationMetricsRequestDTOInput!) {
    getAppCrashReportTrend(
      applicationMetricCriteria: $applicationMetricCriteria
    ) {
      data {
        dateTime
        osDetails {
          osName
          errorCrashRate
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getBounceRateSTTrend = gql`
  query ($applicationMetricCriteria: ApplicationMetricsRequestDTOInput!) {
    getBounceRateSTTrend(
      applicationMetricCriteria: $applicationMetricCriteria
    ) {
      data {
        dateTime
        osDetails {
          osName
          bounceRateInPercentage
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getActiveUserTrend = gql`
  query ($applicationMetricCriteria: ApplicationMetricsRequestDTOInput!) {
    getActiveUserTrend(applicationMetricCriteria: $applicationMetricCriteria) {
      data {
        dateTime
        osDetails {
          osName
          totalUser
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getAvgSessionTime = gql`
  query ($applicationMetricCriteria: ApplicationMetricsRequestDTOInput!) {
    getAvgSessionTime(applicationMetricCriteria: $applicationMetricCriteria) {
      data {
        dateTime
        osDetails {
          osName
          sessionTimeInMs
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getAppPerformanceScoreCardQuery = gql`
  query ($applicationMetricCriteria: ApplicationMetricsRequestDTOInput!) {
    getAppPerformanceScorecard(
      applicationMetricCriteria: $applicationMetricCriteria
    ) {
      data {
        appUpTime
        avgApiAppResponseTime
        crashRate
      }
      errors
      responseCode
      responseMessage
    }
    getIncidentScoreCard(
      applicationMetricCriteria: $applicationMetricCriteria
    ) {
      data {
        dailyActiveUsers
        monthlyIncidents
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getNetworkLatencyTrend = gql`
  query ($applicationMetricCriteria: ApplicationMetricsRequestDTOInput!) {
    getNetworkLatencyTrend(
      applicationMetricCriteria: $applicationMetricCriteria
    ) {
      data {
        dateTime
        osDetails {
          osName
          loadInMs
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getTopSlowestAPI = gql`
  query ($applicationMetricCriteria: ApplicationMetricsRequestDTOInput!) {
    getTopSlowestAPI(applicationMetricCriteria: $applicationMetricCriteria) {
      data {
        name
        val
        valInPer
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getAPIResponseTrend = gql`
  query ($applicationMetricCriteria: ApplicationMetricsRequestDTOInput!) {
    getAPIResponseTrend(applicationMetricCriteria: $applicationMetricCriteria) {
      data {
        dateTime
        apiDetails {
          apiName
          responseTimeInMs
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getErrorRateTrend = gql`
  query ($applicationMetricCriteria: ApplicationMetricsRequestDTOInput!) {
    getErrorRateTrend(applicationMetricCriteria: $applicationMetricCriteria) {
      data {
        dateTime
        osDetails {
          osName
          errorRate
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;
const getApdexScore = gql`
  query ($applicationMetricCriteria: ApplicationMetricsRequestDTOInput!) {
    getApdexScore(applicationMetricCriteria: $applicationMetricCriteria) {
      data {
        chartTitleInPercentage
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getTargetPerformanceTunnel = gql`
  query ($operationalMetricCriteria: OperationalMetricesRequestDTOInput!) {
    getTargetPerformanceTunnel(
      operationalMetricCriteria: $operationalMetricCriteria
    ) {
      data {
        totalRegisteredStores
        targetAssignedStores
        targetStoresInLastThreeMonths
        targetStoresThisMonth
        targetRegisteredVsAssignedStores
        assignedStoresVslastThreeMonths
        lastThreeMonthsVsThisMonth
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

// DSD Shipment Visibility API
const getDwellTimeDetInMin = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getDwellTimeDetInMin(psdCriteria: $psdCriteria) {
      data {
        avgDwellTime
        dwellTimeDeviation
        minDwell
        maxDwell
        arrivalTimeOfDay
        earliestArrival
        latestArrival
        standardDeviationArrival
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getDwellTimeVolumeDetInMin = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getDwellTimeVolumeDetInMin(psdCriteria: $psdCriteria) {
      data {
        overallstDeviation
        range {
          rangeLowerLimit
          rangeUpperLimit
          avgDwellTime
          countOfShipments
        }
        averageShipment
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getProcessingTimes = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getProcessingTimes(psdCriteria: $psdCriteria) {
      data {
        adapterProcessingTime
        visibilityServiceProcessingTime
        subscriptionProcessingTime
        averageProcessingTimeOfAllSteps
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getActiveAndTotalUsersChart = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getActiveAndTotalUsersChart(psdCriteria: $psdCriteria) {
      data {
        activeUser
        totalUser
        dateTime
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getSWStoreAcceptanceRate = gql`
  query ($gsPPMetricCriteria: GSPPMetricsRequestDTOInput!) {
    getSWStoreAcceptanceRate(gsPPMetricCriteria: $gsPPMetricCriteria) {
      data {
        storesWithMinOfOneRecommendationAdded
        totalStoresWithRecommendations
        acceptanceRate
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getRealTimeOnboardingConfig = gql`
  query ($productId: Int) {
    getRealTimeOnboardingConfig(productId: $productId) {
      data {
        productDetails {
          productId
          productName
          applicationNames
          sourceDetails {
            sourceId
            sourceName
            tabId
            tabName
            methodDetails {
              displayName
              methodName
              status
            }
          }
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const handleProductPageRequest = gql`
  query ($productId: Int!, $productName: String!) {
    handleProductPageRequest(productId: $productId, productName: $productName) {
      errors
      responseCode
      responseMessage
    }
  }
`;

const getEmbedToken = gql`
  query ($powerbiCriteria: PowerBiEmbedTokenRequestDTOInput) {
    getEmbedToken(powerbiCriteria: $powerbiCriteria) {
      data {
        embedToken
        accessToken
        expiration
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getGSShareLossByCategory = gql`
  query ($gsPPMetricCriteria: GSPPMetricsRequestDTOInput!) {
    getGSShareLossByCategory(gsPPMetricCriteria: $gsPPMetricCriteria) {
      data {
        subCategoryName
        noOfStoresWithShareLossPredicted
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getServerSideCalendarGenRunTimeTrend = gql`
  query ($applicationMetricCriteria: ApplicationMetricsRequestDTOInput!) {
    getServerSideCalendarGenRunTimeTrend(
      applicationMetricCriteria: $applicationMetricCriteria
    ) {
      data {
        calendarGenerationRunTime
        calendarGenerationRunTimeTrend {
          dateTime
          calendarGenerationRunTime
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

export {
  getMasterData,
  getGSModelFilters,
  getMetricThreshold,
  getThemePreference,
  saveThemePreference,
  getOperationalMetricsOverview,
  getOrderAnalysis,
  getOrderAnalysisGraph,
  getSalesAndDA,
  getSalesAndDALoyaltyTable,
  getDigitalOrdersRegistration,
  getDigitalOrdersSummary,
  getDigitalTotalOrdersCharts,
  getStoreAndUsage,
  getStoreAndUsageChart,
  getAverageNetRevenueStore,
  getAverageOrderStore,
  getAverageOrderStoreSize,
  getStoreVersusPlan,
  getStoreReachingTarget,
  getDeviceOsBreakdown,
  getErrorCrashRateTrend,
  getIncidentTrend,
  getUserAdoptionScorecard,
  getNetworkPerformance,
  getIncidentMonitoringSlaAnalysis,
  getIncidentMonitoringOpenAndClose,
  getIncidentMonitoringPriorityLevelGraph,
  getTopEntryPage,
  getRageClick,
  getIncidentMonitoringTableView,
  getHealthAndStabilityOverview,
  getHealthAndStabilityBarChart,
  getIncidentMonitoringOverview,
  getHealthAndStabilityLineChart,
  getDigitizedStores,
  getStoreSalesRevenueImpactDtl,
  getGSScoreCard,
  getGSOperationalMetrics,
  getPepsiConnectScoreCard,
  getBeesScoreCard,
  getBeesVsPepsiConnectSRT,
  getBeesVsPepsiConnectGrossRevenue,
  getBeesVsPepsiConnectOrder,
  getBeesVsPepsiConnectDropSizeOrder,
  getNetPromoterScore,
  getSalesUplift,
  getSuggestedOrdersRealTime,
  getSuggestedOrdersRealTimeTrend,
  getRevenueIndicator,
  getAvgSoSizeIndex,
  getAllRecommendationServedTrend,
  getGSPPScoreCard,
  getGSSWScoreCard,
  getRecommendationAddedToSellSheetTrend,
  getGSPPAcceptanceRate,
  getTotalNoOfRecommendationForRegion,
  getRecommendationAddedToSellSheetForRegion,
  getUniqueUserTrend,
  getAppCrashReportTrend,
  getBounceRateSTTrend,
  getActiveUserTrend,
  getAvgSessionTime,
  getAppPerformanceScoreCardQuery,
  getNetworkLatencyTrend,
  getTopSlowestAPI,
  getAPIResponseTrend,
  getErrorRateTrend,
  getApdexScore,
  getTargetPerformanceTunnel,
  getDwellTimeDetInMin,
  getDwellTimeVolumeDetInMin,
  getProcessingTimes,
  getActiveAndTotalUsersChart,
  getSWStoreAcceptanceRate,
  getRealTimeOnboardingConfig,
  handleProductPageRequest,
  getCustomerCallMetrices,
  getEmbedToken,
  getGSShareLossByCategory,
  getServerSideCalendarGenRunTimeTrend,
};
