import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import {
  getUserAdoptionScorecard,
  getTopEntryPage,
  getRageClick,
  getGSScoreCard,
  getGSOperationalMetrics,
  getDwellTimeDetInMin,
  getDwellTimeVolumeDetInMin,
  getProcessingTimes,
  getEmbedToken,
} from '../graphql/queries';

import {
  getScorecardOverview,
  getB2BScorecardOverview,
  getOperationalMetricsInOverview,
  getUserBehaviourAndEngagement,
  getApplicationPeformanceMetricsInOverview,
  getGSNBAScoreCard,
  getGSNBAAdoptionRateTrend,
  getGSNBAStoreAdoptionRateTrend,
  getGSNBARecommendation,
  getNBAUseCaseNames,
  getGSNBASalesGrowthTrend,
  getB2BOverViewStoresInfo,
  getB2BOverViewDigitalOrdersInfo,
  getUserAdoptionLoginRate,
  getAfhRecommendationsPageData,
} from '../graphql/overviewQueries';

import {
  getSolutionMonitoringView,
  getShipmentVisibilityScoreCard,
  getIncidentMonitoringView,
  getDwellTimeVolumeDetInMinProd,
} from '../graphql/walmartQueries';
import { helperFunctions } from '../utils/helperFunctions';

export const useOverview = () => {
  const { isDevOrQaEnv } = helperFunctions();
  const [userBehaviourData, setUserBehaviourData] = useState({});
  const [topEntryPageData, setTopEntryPageData] = useState([]);
  const [rageClickData, setRageClickData] = useState([]);
  const [scorecardOverviewData, setScorecardOverviewData] = useState({});
  const [b2bScorecardOverviewData, setB2BScorecardOverviewData] =
    useState(null);

  const [
    operationalMetricsInOverviewData,
    setOperationalMetricsInOverviewData,
  ] = useState({});
  const [
    applicationPeformanceMetricsInOverviewData,
    setApplicationPeformanceMetricsInOverviewData,
  ] = useState({});
  const [userBehaviorAndEngagementData, setUserBehaviorAndEngagementData] =
    useState(null);

  const [gsScoreData, setGSSCoreData] = useState([]);
  const [gsOperationalMetricsData, setGSOperationalMetricsData] = useState([]);

  const [dwellTimeDetInMinData, setDwellTimeDetInMinData] = useState([]);
  const [shipmentVisibilityData, setShipmentVisibilityData] = useState({});
  const [shipmentVisibilityIncidentData, setShipmentVisibilityIncidentData] =
    useState({});
  const [solutionMonitoringData, setSolutionMonitoringData] = useState({});
  const [dwellTimeVolumeDetInMinData, setDwellTimeVolumeDetInMinData] =
    useState([]);
  const [processingTimesData, setProcessingTimesData] = useState([]);
  const [gsNBARecommendationData, setGSNBARecommendationData] = useState([]);
  const [nbaUseCaseNamesData, setNBAUseCaseNamesData] = useState([]);
  const [gsNBASalesGrowthTrendData, setGSNBASalesGrowthTrendData] = useState(
    []
  );

  const [gsNBAScoreCard, setGsNBAScoreCard] = useState({});
  const [adoptionRateTrendData, setAdoptionRateTrendData] = useState({});
  const [storeAdoptionRateTrendData, setStoreAdoptionRateTrendData] = useState(
    {}
  );
  const [embedTokenData, setEmbedTokenData] = useState([]);

  const [b2bOverViewStoresInfoData, setB2BOverViewStoresInfoData] =
    useState(null);
  const [digitalOrdersData, setDigitalOrdersData] = useState([]);
  const [afhRecommendationPageData, setAfhRecommendationPageData] = useState(
    []
  );
  const [userAdoptionLoginRateData, setUserAdoptionLoginRateData] = useState(
    []
  );

  function GetMonthName(utcString) {
    const utcDate = new Date(utcString);
    // Get month and year acronyms
    // const monthAcronym = new Intl.DateTimeFormat("en", {
    //   month: "short",
    // }).format(utcDate);
    // const yearAcronym = utcDate.getFullYear().toString().slice(2);
    // Format the result
    // return `${monthAcronym},${yearAcronym}`;

    return utcDate.toLocaleString('en-US', {
      month: 'short',
    });
  }

  const [getScorecardOverviewData, { loading: getScorecardOverviewLoading }] =
    useLazyQuery(getScorecardOverview, {
      fetchPolicy: 'cache-first',
      onCompleted: (res) => {
        if (res) {
          const {
            getOperationalMetricsOverview,
            getTotalOrderProcessed,
            getTotalRevenueGenerated,
          } = res;
          const structuredData = {};
          if (
            getOperationalMetricsOverview?.data &&
            getOperationalMetricsOverview?.responseCode === 200
          ) {
            structuredData.operationalMetricsOverviewData =
              getOperationalMetricsOverview?.data;
          }
          if (
            getTotalOrderProcessed?.data &&
            getTotalOrderProcessed?.responseCode === 200
          ) {
            structuredData.totalOrderProcessedData =
              getTotalOrderProcessed?.data;
          }
          if (
            getTotalRevenueGenerated?.data &&
            getTotalRevenueGenerated?.responseCode === 200
          ) {
            structuredData.totalRevenueGeneratedData =
              getTotalRevenueGenerated?.data;
          }
          setScorecardOverviewData(structuredData);
        }
      },
    });

  const [
    getB2BScorecardOverviewData,
    { loading: getB2BScorecardOverviewLoading },
  ] = useLazyQuery(getB2BScorecardOverview, {
    // fetchPolicy: "cache-first",
    onCompleted: (res) => {
      if (res) {
        const b2bOverviewScoreCard =
          res.getB2BOverviewScorecard.responseCode === 200
            ? res.getB2BOverviewScorecard.data
            : {};
        const operationalMetricsOverview =
          res.getOperationalMetricsOverview.responseCode === 200
            ? res.getOperationalMetricsOverview.data
            : {};
        setB2BScorecardOverviewData({
          b2bOverviewScoreCard,
          operationalMetricsOverview,
        });
      }
    },
  });

  const [
    getOperationalMetricsInOverviewData,
    { loading: operationalMetricsInOverviewLoading },
  ] = useLazyQuery(getOperationalMetricsInOverview, {
    fetchPolicy: 'cache-first',
    onCompleted: (res) => {
      if (res) {
        const {
          getStoreVersusPlan,
          getStoreReachingTarget,
          getDigitalOrdersSummary,
          getDigitalTotalOrdersCharts,
        } = res;
        const structuredData = {};
        if (
          getStoreVersusPlan?.data &&
          getStoreVersusPlan?.responseCode === 200
        ) {
          structuredData.storeVersusPlanData = getStoreVersusPlan?.data;
        }
        if (
          getStoreReachingTarget?.data &&
          getStoreReachingTarget?.responseCode === 200
        ) {
          structuredData.storeReachingTargetData = getStoreReachingTarget?.data;
        }
        if (
          getDigitalOrdersSummary?.data &&
          getDigitalOrdersSummary?.responseCode === 200
        ) {
          structuredData.digitalOrdersSummaryData =
            getDigitalOrdersSummary?.data;
        }
        if (
          getDigitalTotalOrdersCharts?.data &&
          getDigitalTotalOrdersCharts?.responseCode === 200
        ) {
          const digitalOrdersGraphData = {
            totalDigitalOrders: [],
            digitalNetSale: [],
            month: [],
          };
          const data = getDigitalTotalOrdersCharts?.data;
          data?.forEach((element) => {
            digitalOrdersGraphData.totalDigitalOrders.push(
              element.totalDigitalOrder
            );
            digitalOrdersGraphData.digitalNetSale.push(element.digitalNetSale);
            digitalOrdersGraphData.month.push(GetMonthName(element?.dateTime));
          });
          structuredData.digitalTotalOrdersChartsData = digitalOrdersGraphData;
        }
        setOperationalMetricsInOverviewData(structuredData);
      }
    },
  });

  const [
    getUserBehaviourAndEngagementData,
    { loading: userBehaviorAndEngagementLoading },
  ] = useLazyQuery(getUserBehaviourAndEngagement, {
    fetchPolicy: 'cache-first',
    onCompleted: (res) => {
      if (res) {
        const { getUserAdoptionScorecard, getTopEntryPage, getRageClick } = res;
        const structuredData = {};
        if (
          getUserAdoptionScorecard?.data &&
          getUserAdoptionScorecard?.responseCode === 200
        ) {
          structuredData.userBehaviour = getUserAdoptionScorecard?.data;
        }
        if (getTopEntryPage?.data && getTopEntryPage?.responseCode === 200) {
          structuredData.topEntryPage = getTopEntryPage?.data;
        }
        if (getRageClick?.data && getRageClick?.responseCode === 200) {
          structuredData.rageClick = getRageClick?.data;
        }
        setUserBehaviorAndEngagementData(structuredData);
      }
    },
  });

  const [
    getAfhRecommendationsPage,
    { loading: getAfhRecommendationsPageLoading },
  ] = useLazyQuery(getAfhRecommendationsPageData, {
    fetchPolicy: 'cache-first',
    onCompleted: (res) => {
      console.log('response --- ' + res);
      if (res) {
        const {
          getRecommendationsPage: { data, responseCode },
        } = res;
        if (data && responseCode === 200) {
          setAfhRecommendationPageData(data);
        } else {
          setAfhRecommendationPageData([]);
        }
      }
    },
  });

  const [
    getApplicationPeformanceMetricsInOverviewData,
    { loading: applicationPeformanceMetricsInOverviewLoading },
  ] = useLazyQuery(getApplicationPeformanceMetricsInOverview, {
    fetchPolicy: 'cache-first',
    onCompleted: (res) => {
      if (res) {
        const {
          getAppPerformanceOverviewScorecard,
          getIncidentTrend,
          getIncidentMonitoringOverview,
          getIncidentMonitoringPriorityLevelGraph,
          getIncidentMonitoringOpenAndClose,
          getIncidentMonitoringSlaAnalysis,
        } = res;
        const applicationPeformanceData = {};
        if (
          getAppPerformanceOverviewScorecard?.data &&
          getAppPerformanceOverviewScorecard?.responseCode === 200
        ) {
          applicationPeformanceData.healthAndStabilityData =
            getAppPerformanceOverviewScorecard?.data;
        }
        if (getIncidentTrend?.data && getIncidentTrend?.responseCode === 200) {
          applicationPeformanceData.incidentTrendData = getIncidentTrend?.data;
        }
        if (
          getIncidentMonitoringOverview?.data &&
          getIncidentMonitoringOverview?.responseCode === 200
        ) {
          applicationPeformanceData.incidentMonitoringOverviewData =
            getIncidentMonitoringOverview?.data;
        }
        if (
          getIncidentMonitoringPriorityLevelGraph?.data &&
          getIncidentMonitoringPriorityLevelGraph?.responseCode === 200
        ) {
          applicationPeformanceData.incidentMonitoringPriorityLevelGraphData =
            getIncidentMonitoringPriorityLevelGraph?.data;
        }
        if (
          getIncidentMonitoringOpenAndClose?.data &&
          getIncidentMonitoringOpenAndClose?.responseCode === 200
        ) {
          applicationPeformanceData.incidentMonitoringOpenAndCloseData =
            getIncidentMonitoringOpenAndClose?.data;
        }
        if (
          getIncidentMonitoringSlaAnalysis?.data &&
          getIncidentMonitoringSlaAnalysis?.responseCode === 200
        ) {
          applicationPeformanceData.incidentMonitoringSlaAnalysisData =
            getIncidentMonitoringSlaAnalysis?.data;
        }
        setApplicationPeformanceMetricsInOverviewData(
          applicationPeformanceData
        );
      }
    },
    onError: (err) => {
      console.log('Applicaiton Pefromance Overview Data error : ', err);
    },
  });

  const [getUserBehaviourData, { loading: userBehaviourLoading }] =
    useLazyQuery(getUserAdoptionScorecard, {
      onCompleted: (res) => {
        if (res) {
          const {
            getUserAdoptionScorecard: { data, responseCode },
          } = res;
          if (data && responseCode === 200) {
            setUserBehaviourData(data);
          } else {
            setUserBehaviourData({});
          }
        }
      },
    });

  const [getTopEntryPageData, { loading: topEntryPageLoading }] = useLazyQuery(
    getTopEntryPage,
    {
      onCompleted: (res) => {
        if (res) {
          const {
            getTopEntryPage: { data, responseCode },
          } = res;
          if (data && responseCode === 200) {
            setTopEntryPageData(data);
          } else {
            setTopEntryPageData([]);
          }
        }
      },
    }
  );

  const [getRageClickData, { loading: rageClickLoading }] = useLazyQuery(
    getRageClick,
    {
      onCompleted: (res) => {
        if (res) {
          const {
            getRageClick: { data, responseCode },
          } = res;
          if (data && responseCode === 200) {
            setRageClickData(data);
          } else {
            setRageClickData([]);
          }
        }
      },
    }
  );

  const [getGSScoreCardData, { loading: gsScoreDataLoading }] = useLazyQuery(
    getGSScoreCard,
    {
      fetchPolicy: 'cache-first',
      onCompleted: (res) => {
        if (res) {
          const {
            getGSScoreCard: { data, responseCode },
          } = res;
          if (data && responseCode === 200) {
            setGSSCoreData(data);
          } else {
            setGSSCoreData([]);
          }
        }
      },
    }
  );

  const [
    getGSOperationalMetricsData,
    { loading: gsOperationalMetricsLoading },
  ] = useLazyQuery(getGSOperationalMetrics, {
    fetchPolicy: 'cache-first',
    onCompleted: (res) => {
      if (res) {
        const {
          getGSOperationalMetrics: { data, responseCode },
        } = res;
        if (data && responseCode === 200) {
          setGSOperationalMetricsData(data);
        } else {
          setGSOperationalMetricsData([]);
        }
      }
    },
  });

  const [getDwellTimeDetInMinData, { loading: dwellTimeDetInMinLoading }] =
    useLazyQuery(getDwellTimeDetInMin, {
      onCompleted: (res) => {
        if (res) {
          const {
            getDwellTimeDetInMin: { data, responseCode },
          } = res;
          if (data && responseCode === 200) {
            setDwellTimeDetInMinData(data);
          } else {
            setDwellTimeDetInMinData([]);
          }
        }
      },
    });

  const [getSolutionMonitoringData, { loading: solutionMonitoringLoading }] =
    useLazyQuery(getSolutionMonitoringView, {
      onCompleted: (res) => {
        if (res) {
          const {
            getShipmentMissingEvent,
            getAdapterService,
            getVisibilityService,
            getSubscriptionService,
          } = res;
          const isShipmentMissingEvent =
            getShipmentMissingEvent?.data &&
            getShipmentMissingEvent?.responseCode === 200;
          const isAdapterServiceDataSuccess =
            getAdapterService?.data && getAdapterService?.responseCode === 200;
          const isVisibilityServiceDataSuccess =
            getVisibilityService?.data &&
            getVisibilityService?.responseCode === 200;
          const isSubscriptionServiceDataSuccess =
            getSubscriptionService?.data &&
            getSubscriptionService?.responseCode === 200;
          if (
            isShipmentMissingEvent ||
            isAdapterServiceDataSuccess ||
            isVisibilityServiceDataSuccess ||
            isSubscriptionServiceDataSuccess
          ) {
            const structuredData = {
              shipmentMissingEvent: getShipmentMissingEvent?.data,
              adapterService: getAdapterService?.data,
              visibilityService: getVisibilityService?.data,
              subscriptionService: getSubscriptionService?.data,
            };
            setSolutionMonitoringData(structuredData);
          } else {
            setSolutionMonitoringData({});
          }
        }
      },
    });

  const [
    getShipmentVisibilityScoreCardData,
    { loading: shipmentVisibilityScoreCardLoading },
  ] = useLazyQuery(getShipmentVisibilityScoreCard, {
    onCompleted: (res) => {
      if (res) {
        const {
          getShiVsScoreCard: { data, responseCode },
        } = res;
        if (data && responseCode === 200) {
          setShipmentVisibilityData(data);
        } else {
          setShipmentVisibilityData({});
        }
      }
    },
  });

  const [
    getShipmentVisibilityIncidentMonitoringData,
    { loading: shipmentVisibilityIncidentDataLoading },
  ] = useLazyQuery(getIncidentMonitoringView, {
    onCompleted: (res) => {
      if (res) {
        const {
          getIncidentTrend,
          getIncidentMonitoringTableView,
          getIncidentMonitoringSlaAnalysis,
          getIncidentMonitoringPriorityLevelGraph,
          getIncidentMonitoringOpenAndClose,
          getIncidentMonitoringOverview,
        } = res;
        const structuredData = {};
        const processMetricData = (metric, key) => {
          if (metric?.data && metric?.responseCode === 200) {
            structuredData[key] = metric?.data;
          }
        };
        processMetricData(getIncidentTrend, 'incidentTrendData');
        processMetricData(
          getIncidentMonitoringTableView,
          'incidentMonitoringTableData'
        );
        processMetricData(
          getIncidentMonitoringSlaAnalysis,
          'incidentMonitoringSlaAnalysisData'
        );
        processMetricData(
          getIncidentMonitoringOpenAndClose,
          'incidentMonitoringOpenAndCloseData'
        );
        processMetricData(
          getIncidentMonitoringPriorityLevelGraph,
          'incidentMonitoringPriorityLevelGraphData'
        );
        processMetricData(
          getIncidentMonitoringOverview,
          'incidentMonitoringOverviewData'
        );
        setShipmentVisibilityIncidentData(structuredData);
      }
    },
  });

  const [getDwellTimeVolumeDetInMinData, { loading: dwellTimeVolumeLoading }] =
    useLazyQuery(
      isDevOrQaEnv()
        ? getDwellTimeVolumeDetInMin
        : getDwellTimeVolumeDetInMinProd,
      {
        onCompleted: (res) => {
          if (res) {
            const {
              getDwellTimeVolumeDetInMin: { data, responseCode },
            } = res;
            if (data && responseCode === 200) {
              setDwellTimeVolumeDetInMinData(data);
            } else {
              setDwellTimeVolumeDetInMinData([]);
            }
          }
        },
      }
    );

  const [getProcessingTimesData, { loading: processingTimesLoading }] =
    useLazyQuery(getProcessingTimes, {
      onCompleted: (res) => {
        if (res) {
          const {
            getProcessingTimes: { data, responseCode },
          } = res;
          if (data && responseCode === 200) {
            setProcessingTimesData(data);
          } else {
            setProcessingTimesData([]);
          }
        }
      },
    });

  const [getGSNBAScoreCardData, { loading: nBAScoreCardLoading }] =
    useLazyQuery(getGSNBAScoreCard, {
      onCompleted: (res) => {
        if (res) {
          const {
            getGSNBAScoreCard: { data, responseCode },
          } = res;

          if (data && responseCode === 200) {
            setGsNBAScoreCard(data);
          } else {
            setGsNBAScoreCard({});
          }
        }
      },
      onError: (err) => {
        console.log('getGSNBAScoreCardData Data error : ', err);
      },
    });

  function transformGSNBAData(apiResponse) {
    const useCases = new Set();
    const dateTimeSet = new Set();

    apiResponse?.forEach(({ nbaUseCases, values }) => {
      useCases.add(nbaUseCases);
      values.forEach(({ dateTime }) => {
        dateTimeSet.add(dateTime);
      });
    });

    const finalData = {
      dateTime: Array.from(dateTimeSet).sort(),
      useCasesData: [],
    };

    useCases.forEach((useCase) => {
      const adoptionRates = finalData.dateTime.map((date) => {
        const caseData = apiResponse.find(
          (item) => item.nbaUseCases === useCase
        );
        const value = caseData?.values.find((val) => val.dateTime === date);
        return value ? parseFloat(value.adoptionRate) : null;
      });

      finalData.useCasesData.push({
        useCaseName: useCase,
        adoptionRates,
      });
    });

    return finalData;
  }

  const [getGSNBAAdoptionRateTrendData, { loading: adoptionRateLoading }] =
    useLazyQuery(getGSNBAAdoptionRateTrend, {
      onCompleted: (res) => {
        if (res) {
          const {
            getGSNBAAdoptionRateTrend: { data, responseCode },
          } = res;

          if (data && responseCode === 200) {
            const finalData = transformGSNBAData(data);
            setAdoptionRateTrendData(finalData);
          } else {
            setAdoptionRateTrendData({});
          }
        }
      },
      onError: (err) => {
        console.log('getGSNBAAdoptionRateTrend error : ', err);
      },
    });

  const [
    getGSNBAStoreAdoptionRateTrendData,
    { loading: storeAdoptionRateLoading },
  ] = useLazyQuery(getGSNBAStoreAdoptionRateTrend, {
    onCompleted: (res) => {
      if (res) {
        const {
          getGSNBAStoreAdoptionRateTrend: { data, responseCode },
        } = res;

        if (data && responseCode === 200) {
          const finalData = transformGSNBAData(data);
          setStoreAdoptionRateTrendData(finalData);
        } else {
          setStoreAdoptionRateTrendData({});
        }
      }
    },
    onError: (err) => {
      console.log('getGSNBAStoreAdoptionRateTrend error : ', err);
    },
  });
  const [getGSNBARecommendationData, { loading: gsNBARecommendationLoading }] =
    useLazyQuery(getGSNBARecommendation, {
      onCompleted: (res) => {
        if (res) {
          const {
            getGSNBARecommendation: { data, responseCode },
          } = res;
          if (data && responseCode === 200) {
            setGSNBARecommendationData(data);
          } else {
            setGSNBARecommendationData([]);
          }
        }
      },
    });

  const [getNBAUseCaseNamesData, { loading: nbaUseCaseNamesDataLoading }] =
    useLazyQuery(getNBAUseCaseNames, {
      onCompleted: (res) => {
        if (res) {
          const {
            getNBAUseCaseNames: { data, responseCode },
          } = res;
          if (data && responseCode === 200) {
            setNBAUseCaseNamesData(data);
          } else {
            setNBAUseCaseNamesData([]);
          }
        }
      },
    });

  const [
    getGSNBASalesGrowthTrendData,
    { loading: gsNBASalesGrowthTrendLoading },
  ] = useLazyQuery(getGSNBASalesGrowthTrend, {
    onCompleted: (res) => {
      if (res) {
        const {
          getGSNBASalesGrowthTrend: { data, responseCode },
        } = res;
        if (data && responseCode === 200) {
          setGSNBASalesGrowthTrendData(data);
        } else {
          setGSNBASalesGrowthTrendData([]);
        }
      }
    },
  });

  const [getEmbedTokenData] = useLazyQuery(getEmbedToken, {
    onCompleted: (res) => {
      if (res) {
        const {
          getEmbedToken: { data, responseCode },
        } = res;
        if (data && responseCode === 200) {
          setEmbedTokenData(data);
        } else {
          setEmbedTokenData([]);
        }
      }
    },
  });

  const [
    getB2BOverViewStoresInfoData,
    { loading: getB2BOverViewStoresInfoLoading },
  ] = useLazyQuery(getB2BOverViewStoresInfo, {
    onCompleted: (res) => {
      if (res) {
        const {
          getB2BOverViewStoresInfo: { data, responseCode },
        } = res;
        if (data && responseCode === 200) {
          setB2BOverViewStoresInfoData(data);
        } else {
          setB2BOverViewStoresInfoData([]);
        }
      }
    },
  });

  const [getDigitalOrdersData, { loading: getDigitalOrdersLoading }] =
    useLazyQuery(getB2BOverViewDigitalOrdersInfo, {
      onCompleted: (res) => {
        if (res) {
          const {
            getB2BOverViewDigitalOrdersInfo: { data, responseCode },
          } = res;
          if (data && responseCode === 200) {
            setDigitalOrdersData(data);
          } else {
            setDigitalOrdersData([]);
          }
        }
      },
    });

  const [
    getUserAdoptionLoginRateData,
    { loading: userAdoptionLoginRateLoading },
  ] = useLazyQuery(getUserAdoptionLoginRate, {
    onCompleted: (res) => {
      if (res) {
        const {
          getUserAdoptionLoginRate: { data, responseCode },
        } = res;
        if (data && responseCode === 200) {
          setUserAdoptionLoginRateData(data);
        } else {
          setUserAdoptionLoginRateData([]);
        }
      }
    },
  });

  return {
    scorecardOverviewData,
    b2bScorecardOverviewData,
    getScorecardOverviewData,
    getScorecardOverviewLoading,
    getB2BScorecardOverviewData,
    getB2BScorecardOverviewLoading,
    operationalMetricsInOverviewData,
    getOperationalMetricsInOverviewData,
    operationalMetricsInOverviewLoading,
    userBehaviorAndEngagementData,
    getUserBehaviourAndEngagementData,
    userBehaviorAndEngagementLoading,
    applicationPeformanceMetricsInOverviewData,
    getApplicationPeformanceMetricsInOverviewData,
    applicationPeformanceMetricsInOverviewLoading,
    getUserBehaviourData,
    userBehaviourData,
    userBehaviourLoading,
    getTopEntryPageData,
    topEntryPageData,
    topEntryPageLoading,
    getRageClickData,
    rageClickData,
    rageClickLoading,
    getGSScoreCardData,
    gsScoreData,
    gsScoreDataLoading,
    getGSOperationalMetricsData,
    gsOperationalMetricsData,
    gsOperationalMetricsLoading,
    dwellTimeDetInMinData,
    getDwellTimeDetInMinData,
    dwellTimeDetInMinLoading,
    getSolutionMonitoringData,
    solutionMonitoringLoading,
    solutionMonitoringData,
    getShipmentVisibilityScoreCardData,
    shipmentVisibilityScoreCardLoading,
    shipmentVisibilityData,
    getShipmentVisibilityIncidentMonitoringData,
    shipmentVisibilityIncidentDataLoading,
    shipmentVisibilityIncidentData,
    getDwellTimeVolumeDetInMinData,
    dwellTimeVolumeLoading,
    dwellTimeVolumeDetInMinData,
    getProcessingTimesData,
    processingTimesData,
    processingTimesLoading,
    getGSNBAScoreCardData,
    gsNBAScoreCard,
    nBAScoreCardLoading,
    getGSNBAAdoptionRateTrendData,
    adoptionRateTrendData,
    adoptionRateLoading,
    getGSNBAStoreAdoptionRateTrendData,
    storeAdoptionRateTrendData,
    storeAdoptionRateLoading,
    getGSNBARecommendationData,
    gsNBARecommendationData,
    gsNBARecommendationLoading,
    getNBAUseCaseNamesData,
    nbaUseCaseNamesData,
    nbaUseCaseNamesDataLoading,
    getGSNBASalesGrowthTrendData,
    gsNBASalesGrowthTrendData,
    gsNBASalesGrowthTrendLoading,
    getEmbedTokenData,
    b2bOverViewStoresInfoData,
    getB2BOverViewStoresInfoData,
    getB2BOverViewStoresInfoLoading,
    digitalOrdersData,
    getDigitalOrdersData,
    getDigitalOrdersLoading,
    embedTokenData,
    getUserAdoptionLoginRateData,
    userAdoptionLoginRateData,
    userAdoptionLoginRateLoading,
    afhRecommendationPageData,
    getAfhRecommendationsPage,
    getAfhRecommendationsPageLoading,
  };
};
